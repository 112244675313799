.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
	&_bg {
		background-position: top center;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 100%;
			height: 110px;
			bottom: 100%;
			background-image: url(../images/cont-top-bg.png);
			background-position: center;
			@include respond-to('mobile-m') {
				display: none;
			}
		}
	}
}

.title-block {
	position: relative;
	text-align: center;
	font-size: 0;
	&_mb {
		margin-bottom: 30px;
		@include respond-to('large') {
		}
		@include respond-to('small') {
			margin-bottom: 30px;
		}
		@include respond-to('extra-small') {
			margin-bottom: 25px;
		}
	}
	& .title {
		display: inline-block;
		vertical-align: middle;
		max-width: 20%;
		width: 100%;
		@include respond-to('large') {
			max-width: 30%;
		}
		@include respond-to('small') {
			max-width: 50%;
		}
		@include respond-to('extra-small') {
			max-width: 100%;
		}
	}
	& .decor-line {
		display: inline-block;
		vertical-align: middle;
		max-width: 40%;
		width: 100%;
		@include respond-to('large') {
			max-width: 35%;
		}
		@include respond-to('small') {
			max-width: 25%;
		}
		@include respond-to('extra-small') {
			display: none;
		}
		&__left {}
		&__right {}
		& img {
			height: 1px;
			max-width: 100%;
		}
	}
}

.title {
	margin: 0;
	color: #fff;
	font-size: 40px;
	font-weight: 900;
	line-height: 0.9;
	text-align: center;
	@include respond-to('medium') {
		font-size: 35px;
	}
	@include respond-to('extra-small') {
		font-size: 30px;
	}
	& span {
		display: block;
		font-weight: 400;
	}
	&_accent {
		color: $title;
	}
	&_bolt {
		font-weight: 700;
	}
}

.hp-about {
	position: relative;
	z-index: 1;
	top: -100px;
	margin-bottom: -100px;
	padding: 190px 0;
	background-position: top center;
	background-size: cover;
	@include respond-to('medium') {
		padding: 130px 0;
	}
	@include respond-to('small') {
		top: -120px;
		margin-bottom: -120px;
	}
	&__title {
		margin-bottom: 45px;
		@include respond-to('small') {
			margin-bottom: 25px;
		}
	}
	&__desc {
		& p {
			color: #fff;
			text-align: center;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.6;
			@include respond-to('extra-small') {
				font-size: 16px;
				line-height: 1.2;
				text-align: justify;
			}
		}
	}
}

.hp-services {
	position: relative;
	top: -100px;
	margin-bottom: -100px;
	padding: 130px 0;
	@include respond-to('large') {
		padding-bottom: 70px;
	}
	@include respond-to('small') {
		top: -120px;
		margin-bottom: -120px;
		padding: 70px 0;
	}
	@include respond-to('extra-small') {
		padding: 70px 0 50px;
	}
	&__title {
		color: $title;
	}
	&__items {
		padding-top: 65px;
		@include respond-to('1100') {
		}
		@include respond-to('small') {
		}
		@include respond-to('extra-small') {
			padding-top: 40px;
		}
	}
}

.services {
	font-size: 0;
	text-align: center;
	&__item {
		display: inline-block;
		vertical-align: top;
		font-size: initial;
		width: 33.33%;
		@include respond-to('small') {
			width: 50%;
			margin-bottom: 25px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		@include respond-to('extra-small') {
			width: 100%;
		}
	}
}

.service {
	display: block;
	max-width: 280px;
	margin: 0 auto;
	padding: 0 15px;
	@include link_no-hover($title);
	&__icon {
		height: 96px;
		margin-bottom: 20px;
		@include respond-to('small') {
			height: 80px;
		}
		& img {
			display: inline-block;
			vertical-align: bottom;
			max-height: 100%;
		}
	}
	&__title {
		margin-bottom: 25px;
		color: $title;
		font-size: 30px;
		font-weight: 900;
		line-height: 1;
		@include link_no-hover($title);
		@include respond-to('extra-small') {
			font-size: 25px;
			margin-bottom: 20px;
		}
	}
	&__desc {
		font-size: 18px;
		font-weight: 400;
		color: $title;
		line-height: 1.6;
		@include link_no-hover($title);
		@include respond-to('small') {
			font-size: 16px;
			line-height: 1.2;
			text-align: center;
		}
	}
}

.decor-top {
	position: absolute;
	z-index: 1;
	top: -150px;
	right: 0;
	@include respond-to('1750') {
		right: -100px;
	}
	@include respond-to('1550') {
		top: -70px;
	}
	@include respond-to('1100') {
		right: -50px;
		& img {
			max-width: 300px;
		}
	}
	@include respond-to('medium') {
		top: -30px;
	}
	@include respond-to('small') {
		display: none;
	}
}

.decor-bottom {
	position: absolute;
	left: 0;
	bottom: 35px;
	@include respond-to('1750') {
		left: -90px;
	}
	@include respond-to('1550') {
		bottom: 5px;
		left: -50px;
		& img {
			max-width: 350px;
		}
	}
	@include respond-to('larger') {
		left: -100px;
	}
	@include respond-to('large') {
		display: none;
	}
}