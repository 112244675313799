.breadcrumb {
	position: relative;
	z-index: 1;
	top: -23px;
	background-color: transparent;
	margin-bottom: 0;
	padding: 14px 0;
	@include respond-to('small') {
		display: none;
	}
	& li {
		font-size: 16px;
		&::before {
			color: #b6b6b6;
		}
		& a {
			text-decoration: none;
			color: #b6b6b6;
			font-size: 16px;
			font-weight: 300;
		}
	}
	& .active {
		color: #2a2b2a;
	}
}
