.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: #fff;
	box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	transition: all 0.4s ease;
}

.header-desktop {
	// height: 100px;
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
	height: 125px;
	padding-top: 10px;
	background-image: url(../images/header-back.jpg);
	background-position: center;
	// background-size: cover;
	text-align: center;
	transition: height 0.2s ease, padding 0.2s ease;
	& a {
		& img {
			max-width: 400px;
			transition: max-width 0.2s ease;
		}
	}
}

.main-navi {
	display: block;
	padding: 10px 0;
	text-align: center;
	background-color: $title;
	background-image: $grad;
	font-size: 0;
	transition: padding 0.2s ease;
	& > ul {
		display: inline-block;
		list-style: none;
		margin: 0;
		padding: 0;
		& > li {
			float: $right;
			margin-#{$left}: 1px;
			position: relative;
			&:last-child {
				margin-#{$left}: 0px;
				& a {
					&::after {
						display: none;
					}
				}
			}
			& > a {
				position: relative;
				font-size: 18px;
				font-weight: 500;
				color: #fff;
				padding: 2px 20px;
				display: block;
				&::after {
					content: '';
					position: absolute;
					#{$left}: -1px;
					top: 50%;
					transform: translateY(-50%);
					height: 80%;
					width: 1px;
					background-color: $accent;
				}
				&:hover {
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {}
			}
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 5px;
		float: $left;
		& > img {
			width: 140px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

.main-header.scrolling {
	.header-desktop {}
	.main-navi {
		padding: 5px 0;
		& > ul {}
	}
	.logo {
		height: 80px;
		padding-top: 7px;
		& img {
			max-width: 265px;
		}
	}
}