.wrap-content {
	padding: 20px 0;
	@include respond-to('large') {
	}
	@include respond-to('extra-small') {
		padding: 20px 0;
	}
}

.wrap-all-content-not-banner {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.content-text {
	h2,
	h3 {
		font-size: 30px;
		font-weight: 600;
		line-height: 1.2;
		color: $title;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 26px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1.4;
		text-align: center;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}