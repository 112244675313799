footer {
	width: 100%;
	position: relative;
	z-index: 1;
	padding: 40px 0 15px;
	background-color: #fff;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 -5px 45px 0 rgba(0, 0, 0, 0.1);
	@include respond-to('1100') {
		padding: 30px 0 15px;
	}
	@include respond-to('extra-small') {
		padding: 20px 0 15px;
	}
}

.logo__ftr {
	text-align: center;
	& a {
		display: inline-block;
		& img {
			max-width: 200px;
		}
	}
}

.ftr-contact {
	text-align: center;
	padding: 30px 0 40px;
	@include respond-to('medium') {
		padding: 25px 0px;
	}
	@include respond-to('mobile-m') {
		padding: 15px 0;
	}
	& ul {
		display: inline-block;
		margin: 0;
		padding: 0;
		list-style: none;
		@include respond-to('extra-small') {
			max-width: 95%;
		}
		& li {
			display: inline-block;
			text-align: center;
			margin-#{$left}: 45px;
			&:last-child {
				margin-#{$left}: 0;
			}
			@include respond-to('large') {
				margin-#{$left}: 25px;
			}
			@include respond-to('medium') {
				width: 100%;
				margin-#{$left}: 0;
			}
			@include respond-to('extra-small') {
				width: 100%;
			}
			& a,
			div {
				font-size: 18px;
				font-weight: 600;
				color: $title;
				@include link_no-hover($title);
				@include respond-to('extra-small') {
					font-size: 18px;
				}
				& span {
					display: inline-block;
					& i {
						margin-#{$left}: 14px;
						font-size: 20px;
						color: #2771be;
					}
				}
			}
			& .ltr {
				& span {
					direction: ltr;
				}
			}
		}
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #dedede;
	margin-top: 0;
	margin-bottom: 15px;
}