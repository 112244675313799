.horizontal-form {
	position: relative;
	z-index: 1;
	padding: 47px 0 37px;
	background-image: $grad;
	@include respond-to('1100') {
		padding: 30px 0 35px;
	}
}

.form-horizontal {
	& .contact-us-form {
		overflow: hidden;
	}
	& .contact-us-form__title {
		max-width: 250px;
		text-align: $right;
		font-size: 18px;
		font-weight: 400;
		color: #fff;
		line-height: 1;
		@include respond-to('1100') {
			text-align: center;
			margin: 0 auto;
		}
		& span {
			display: block;
			font-size: 25px;
			font-weight: 900;
		}
	}
	& .contact-wrap-input {
		@include respond-to('large') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $right;
		vertical-align: top;
		margin: 0 0 0 10px;
		max-width: 228px;
		width: 100%;
		@include respond-to('larger') {
			max-width: 255px;
		}
		@include respond-to('large') {
			// max-width: 255px;
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
		}
		@include respond-to('1100') {
			// max-width: 23%;
		}
		@include respond-to('medium') {
			// max-width: 50%;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
			// max-width: 100%;
		}
		&_title {
			max-width: 255px;
			width: 100%;
			@include respond-to('large') {
				max-width: 255px;
			}
			@include respond-to('1100') {
				display: block;
				margin: 0 auto 10px; 	
				max-width: 265px;
			}
		}
		&_ml {
			margin-#{$left}: 0;
		}
		&_button {
			max-width: 180px;
			float: $left;
			margin: 0;
			// margin-#{$left}: 30px;
			@include respond-to('huge') {
				// max-width: 160px;
			}
			@include respond-to('large') {
				display: block;
				margin: 0 auto;
				max-width: 255px;
				width: 100%;
				float: none;
			}
			@include respond-to('extra-small') {
				// margin-top: 10px;
				// max-width: 44%;
				min-width: 255px;
			}
		}
	}
	& .newslatter-input {
		height: 45px;
		width: 100%;
		padding: 11px 10px 10px;
		background-color: rgba($color: #fff, $alpha: 0.7);
		color: #383838;
		font-weight: 400;
		font-size: 20px;
		box-shadow: none;
		// border: 1px solid #e7e7e7;
		border-radius: 0;
		@include respond-to('extra-small') {
			padding: 14px 10px 13px;
		}
		&::placeholder {
			transition: opacity 0.2s ease;
			opacity: 1;
			font-size: 20px;
			font-weight: 400;
			color: #383838;
		}
		&:focus {
			&::placeholder {
				opacity: 0;
			}
		}
	}
	& .newslatter-btn {
		position: relative;
		width: 100%;
		margin: 0;
		height: 45px;
		font-size: 20px;
		font-weight: 900;
		background-color: #d1d1d1;
		background: linear-gradient(to right, #d1d1d1 0%, #f5f5f5 31%, #f5f5f5 65%, #d4d4d4 100%);
		border: none;
		color: $title;
		z-index: 1;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #f5f5f5 0%, #d1d1d1 31%, #d1d1d1 65%, #f5f5f5 100%);
			opacity: 0;
			z-index: -1;
			transition: opacity 0.2s ease;
		}
		&:hover {
			&::after {
				opacity: 1;
			}
		}
		&:active {
			@include btn-effect-active;
		}
	}
	.help-block {
		font-size: 16px;
		color: #fff !important;
	}
	
	.msg-success {
		color: #fff;
	}
}


.form-control:focus {
	border-radius: 0;
}