.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 500px;
	@include respond-to('medium') {
		min-height: 450px;
	}
	@include respond-to('small') {
		min-height: 400px;
	}
	@include respond-to('extra-small') {
		min-height: 300px;
	}
	@include respond-to('mobile-m') {
		min-height: 250px;
	}
}

.banner-top-content__image {
	background-position: center center;
	background-size: cover;
	height: 500px;
	box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
	@include respond-to('medium') {
		height: 450px;
	}
	@include respond-to('small') {
		height: 400px;
	}
	@include respond-to('extra-small') {
		height: 300px;
	}
	@include respond-to('mobile-m') {
		height: 250px;
	}
}